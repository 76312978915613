// Theme specific colors
$brandGreen: #187324;
$gray0: #000000;
$gray1: #333333;
$gray2: #666666;
$gray3: #999999;
$gray4: #FFFFFF;
$grayInactive: #AAAAAA;
$grayDisabled: #D1D1D1;
$grayGraph: #CCCCCC;
$grayChevron: #D1D1D1;
$grayChevronStrong: #333333;
$grayLine: #E5E5E5;
$grayLineStrong: #BDBDBD;
$grayWaterMark: #F3F3F3;
$grayBackground: #F3F3F3;
$grayBackgroundStrong: #EEEEEE;
$grayBackgroundModal: #F1F1F1;
$grayBackgroundApp: #F9F9F9;
$grayBorder: #F9F9F9;
$grayCard: #FFFFFF;
$grayCardDark: #E8E8E8;
$grayButton: #FFFFFF;

// Brand colors
$euro_50: #EFFAEB;
$euro_75: #E3F5DA;
$euro_100: #D8F1CC;
$euro_200: #BEE8AB;
$euro_300: #A3DF88;
$euro_400: #8DD76D;
$euro_500: #79D053;
$euro_600: #6ABF4B;
$euro_700: #56AA41;
$euro_800: #429637;
$euro_900: #187324;
$dollar_50: #E5F4EB;
$dollar_75: #D2ECDD;
$dollar_100: #BFE3CE;
$dollar_200: #96D0AF;
$dollar_300: #6BBF90;
$dollar_400: #48B27A;
$dollar_500: #1CA564;
$dollar_600: #15965A;
$dollar_700: #0B844D;
$dollar_800: #057342;
$dollar_900: #00542E;
$yuan_50: #FEE7E8;
$yuan_100: #FBC3C4;
$yuan_200: #FBC3C4;
$yuan_300: #D16056;
$yuan_400: #D33D2D;
$yuan_500: #D12B05;
$yuan_600: #C41F0A;
$yuan_700: #B41206;
$yuan_800: #A70700;
$yuan_900: #980000;
$rand_50: #E0F1F1;
$rand_100: #B2DCDB;
$rand_200: #80C6C4;
$rand_300: #4FAFAC;
$rand_400: #2D9E9A;
$rand_500: #0E8D87;
$rand_600: #0D817A;
$rand_700: #0C716A;
$rand_800: #0A615B;
$rand_900: #06463E;
$nok_50: #F4E4F4;
$nok_100: #E3BCE4;
$nok_200: #D28FD2;
$nok_300: #BF62C0;
$nok_400: #B13EB3;
$nok_500: #A215A6;
$nok_600: #9512A1;
$nok_700: #820C99;
$nok_800: #720792;
$nok_900: #530185;
$peso_50: #EEF8FD;
$peso_100: #EBF5FD;
$peso_200: #E2F2FB;
$peso_300: #D6ECFA;
$peso_400: #C7E7FB;
$peso_500: #89C8F0;
$peso_600: #66A5E2;
$peso_700: #377DC0;
$peso_800: #264D90;
$peso_900: #1E3358;
$rubel_50: #FFECF0;
$rubel_100: #FFCED6;
$rubel_200: #F79CA1;
$rubel_300: #F0747C;
$rubel_400: #FD515A;
$rubel_500: #FF3C40;
$rubel_600: #F5333F;
$rubel_700: #E22839;
$rubel_800: #D61F32;
$rubel_900: #C70A25;
$yen_50: #FCFCE6;
$yen_100: #F8F6C2;
$yen_200: #F3F19A;
$yen_300: #EFEB72;
$yen_400: #ECE653;
$yen_500: #E8E233;
$yen_600: #E9D12D;
$yen_700: #E8B922;
$yen_800: #E6A216;
$yen_900: #E37A00;
$sek_50: #F0E6FE;
$sek_100: #D6C2FD;
$sek_200: #BA9AFC;
$sek_300: #9C6BFD;
$sek_400: #8246FC;
$sek_500: #6415FA;
$sek_600: #5610F3;
$sek_700: #4000EB;
$sek_800: #1C00E6;
$sek_900: #0000CC;