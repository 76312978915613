@import '~bootstrap/scss/bootstrap';
@import "./constants/colors.scss";

@font-face {
  font-family: "Aestetico-Light";
  src: url("./assets/fonts/Aestetico-Light.otf");
}

@font-face {
  font-family: "Aestetico-Regular";
  src: url("./assets/fonts/Aestetico-Regular.otf");
}

@font-face {
  font-family: "Aestetico-SemiBold";
  src: url("./assets/fonts/Aestetico-SemiBold.otf");
}

@font-face {
  font-family: "Aestetico-Bold";
  src: url("./assets/fonts/Aestetico-Bold.otf");
}

html,
body {
  height: 100%;
  color: $gray1
}

body {
  background-color: $grayBackgroundApp;
  margin: 0;
  font-family: "Aestetico-Light";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a,
p,
input,
small {
  font-family: "Aestetico-Light";
  font-size: 16px;
  line-height: 1.6rem;
}

a {
  text-decoration: 'none';
  color: $gray1
}

a:hover {
  color: $euro_600;
}

label {
  font-family: "Aestetico-Light";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'Aestetico-Light';
}

.custom-switch .custom-control-label::before {
  background-color: $gray3;
}

.custom-control-input ~ .custom-control-label::after {
  background-color: #fff;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $euro_800;
  border-color: $euro_800;
}

.form-control, .form-control:focus, .form-control:active {
  border: 'none';
  box-shadow: none;
}

.form-check-input:checked {
  background-color: $euro_800;
  border-color: $euro_800;
}

.dropdown-menu {
  color: $gray1;
}

.dropdown-item {
  color: $gray1;
  border-radius: 8px;
}

.dropdown-item:hover {
  background-color: $euro_300
}

.dropdown-toggle::after {
  vertical-align: 0;
}

.modal-content {
  background-color: $grayBackgroundApp;
}

.agiAdjustmentSubHeader {
  margin-top: 30px;
  margin-bottom: 10px;
}

.agiAdjustmentSummary {
  margin-top: 20px;
}

.agiAdjustmentButton {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
  min-width: 120px;
  height: 40px;
  border-color: $dollar_900;
  border-style: solid;
  border-radius: 20px;
  border-width: 0;
  font-family: "Aestetico-Light";
  font-size: 14px;
  background-color: $euro_800;
  color: #ffffff;
}

.agiAdjustmentButtonSmall {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  min-width: 120px;
  height: 25px;
  border-color: $dollar_900;
  border-style: solid;
  border-radius: 12px;
  border-width: 0;
  font-family: "Aestetico-Light";
  font-size: 14px;
  background-color: $euro_800;
  color: #ffffff;
}

.agiAdjustmentFileInputZone {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 190px;
  padding-left: 30px;
  padding-right: 30px;
  height: 40px;
  border-color: $dollar_900;
  border-style: solid;
  border-radius: 20px;
  border-width: 0;
  font-family: "Aestetico-Light";
  font-size: 14px;
  background-color: $euro_800;
  color: #ffffff;
  cursor: pointer;
}

.agiAdjustmentFileInputZone input[type=file] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.agiAdjustmentFileSelectorContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  column-gap: 20px;
}

.agiAdjustmentConfirmButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  column-gap: 20px;
}

.agiAdjustmentErrorContainer {
  margin-top: 30px;
}

.agiAdjustmentLoadingIndicator {
  width: 32px;
  height: 32px;
  border: 4px solid $gray1;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}


.foraAdjustmentSubHeader {
  margin-top: 30px;
  margin-bottom: 10px;
}

.foraAdjustmentSummary {
  margin-top: 20px;
}

.foraAdjustmentButton {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
  min-width: 120px;
  height: 40px;
  border-color: $dollar_900;
  border-style: solid;
  border-radius: 20px;
  border-width: 0;
  font-family: "Aestetico-Light";
  font-size: 14px;
  background-color: $euro_800;
  color: #ffffff;
}

.foraAdjustmentButtonSmall {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  min-width: 120px;
  height: 25px;
  border-color: $dollar_900;
  border-style: solid;
  border-radius: 12px;
  border-width: 0;
  font-family: "Aestetico-Light";
  font-size: 14px;
  background-color: $euro_800;
  color: #ffffff;
}

.foraAdjustmentFileInputZone {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 190px;
  padding-left: 30px;
  padding-right: 30px;
  height: 40px;
  border-color: $dollar_900;
  border-style: solid;
  border-radius: 20px;
  border-width: 0;
  font-family: "Aestetico-Light";
  font-size: 14px;
  background-color: $euro_800;
  color: #ffffff;
  cursor: pointer;
}

.foraAdjustmentFileInputZone input[type=file] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.foraAdjustmentFileSelectorContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  column-gap: 20px;
}

.foraAdjustmentConfirmButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  column-gap: 20px;
}

.foraAdjustmentErrorContainer {
  margin-top: 30px;
}

.foraAdjustmentLoadingIndicator {
  width: 32px;
  height: 32px;
  border: 4px solid $gray1;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.payrollFileProcessorHeader {
}

.payrollFileProcessorSubHeader {
  margin-top: 20px;
}

.payrollFileProcessorUploadContainer {

}

.payrollFileProcessorFileSelectorContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  column-gap: 20px;
}

.payrollFileProcessorSummaryContainer {
  margin-bottom: 60px;
}

.payrollFileProcessorApproveButtonContainer {

}

.payrollFileProcessorErrorContainer {
  margin-top: 30px;
}

.payrollFileProcessorLoadingIndicator {
  width: 32px;
  height: 32px;
  border: 4px solid $gray1;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.payrollFileProcessorApproveButton {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
  min-width: 120px;
  height: 40px;
  border-color: $dollar_900;
  border-style: solid;
  border-radius: 20px;
  border-width: 0;
  font-family: "Aestetico-Light";
  font-size: 14px;
  background-color: $euro_800;
  color: #ffffff;
}

.payrollFileProcessorInputZone {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 200px;
  padding-left: 30px;
  padding-right: 30px;
  height: 40px;
  border-color: $dollar_900;
  border-style: solid;
  border-radius: 20px;
  border-width: 0;
  font-family: "Aestetico-Light";
  font-size: 14px;
  background-color: $euro_800;
  color: #ffffff;
  cursor: pointer;
}

.payrollFileProcessorInputZone input[type=file] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.payrollFileProcessorTransactionsTable {

}

.payrollFileProcessorWarningRow {
  background-color: $rubel_100;
}

.employeeImportHeader {
}

.employeeImportSubHeader {
  margin-top: 30px;
  margin-bottom: 10px;
}

.employeeImportFileInputZone {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 190px;
  padding-left: 30px;
  padding-right: 30px;
  height: 40px;
  border-color: $dollar_900;
  border-style: solid;
  border-radius: 30px;
  border-width: 0;
  font-family: "Aestetico-Light";
  font-size: 14px;
  background-color: $euro_800;
  color: #ffffff;
  cursor: pointer;
}

.employeeImportFileInputZone input[type=file] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.employeeImportFileSelectorContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  column-gap: 20px;
}

.employeeImportErrorContainer {
  margin-top: 30px;
}

.employeeImportLoadingIndicator {
  width: 32px;
  height: 32px;
  border: 4px solid $gray1;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.employeeImportFileUploadContainer {

}

.employeeImportTable {

}

.employerInitiatedPayoutPaydaySelectorContainer {
  position: relative;
  width: 150px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: $euro_600;
  background-color: $grayCard;
  margin-bottom: 20px;
}

.employerInitiatedPayoutPaydaySelector {
  width: 100%;
  height: 40px;
  border: none;
  background-color: transparent;
  padding-left: 5px;
}

.employerInitiatedPayoutPaydaySelector :focus {
  border: none;
  outline: none;
}

.employerInitiatedPayoutApprovalContainer > table
{
  width: 100%
}

.employerInitiatedPayoutApprovalContainer > p
{
  font-size: 18px;
}

.employerInitiatedPayoutApprovalSummaryContainer
{
  background-color: $euro_50;
  padding: 10px;
  margin-bottom: 20px;
}

.employerInitiatedPayoutApprovalSummaryContainer span
{
  margin: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
}

.employerInitiatedPayoutApprovalSummaryContainer > p
{
  margin: 0px;
  font-size: 14px;
  line-height: normal;
}

.employerInitiatedPayoutManualCreatorForm
{
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: end;
  margin-bottom: 20px;
  gap: 10px;
}

.employerInitiatedPayoutManualCreatorForm > div
{
  display: flex;
  flex: 1;
  flex-direction: column;
}

.employerInitiatedPayoutManualCreatorForm > div:first-child
{
  display: flex;
  flex: 3;
  flex-direction: column;
}

.employerInitiatedPayoutDropdown {
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: $euro_600;
  background-color: $grayCard;
}

.employerInitiatedPayoutDropdown :focus {
  border: none;
  outline: none;
}

.employerInitiatedPayoutInputNumber {
  height: 2.25rem;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: $euro_600;
  background-color: $grayCard;
}

.employerInitiatedPayoutInputNumber :focus {
  border: none;
  outline: none;
}

.employerInitiatedPayoutButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  column-gap: 20px;
}

.employerInitiatedPayoutPrimaryButton
{
  width: 190px;
  height: 40px;
  border-radius: 30px;
  border-width: 0;
  font-family: "Aestetico-Light";
  font-size: 14px;
  background-color: $euro_800;
  color: #ffffff;
  cursor: pointer;
}

.employerInitiatedPayoutSecondaryButton
{
  width: 190px;
  height: 40px;
  border-radius: 30px;
  border-width: 0;
  font-family: "Aestetico-Light";
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}

.employerInitiatedPayoutDeleteIcon {
  cursor: pointer;
}

.employerInitiatedPayoutBadgeColumn
{
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 1%;
  white-space: nowrap;
}

.employerInitiatedPayoutWarningBadge {
  border-radius: 30px;
  border-width: 0;
  font-family: "Aestetico-Light";
  font-size: 14px;
  background-color: $yen_200;
  padding-left: 10px;
  padding-right: 10px;
}

.employerInitiatedPayoutBasisItemNotIncluded
{
  color: $grayDisabled;
}

.employerInitiatedPayoutArticleTableContainer
{
  margin-left: 30px;
  margin-right: 30px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: $grayCardDark;
}

.employerInitiatedPayoutBasisItemTable
{
  width: 150px;
  font-size: 12px;
}

.employerInitiatedPayoutFileInputZone {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 190px;
  padding-left: 30px;
  padding-right: 30px;
  height: 40px;
  border-color: $dollar_900;
  border-style: solid;
  border-radius: 30px;
  border-width: 0;
  font-family: "Aestetico-Light";
  font-size: 14px;
  background-color: $euro_800;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 20px;
}

.employerInitiatedPayoutFileInputZone input[type=file] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

// multiselect
.rmsc {
  --rmsc-border: 0px !important;
}

.dropdown-container {
  border: 0px !important;
}

.dropdown-container:focus-within {
  box-shadow: none !important;
}
